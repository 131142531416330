import { currentMonth } from './../../../../utils/date-time';
import { map, first } from 'rxjs/operators';
import { leaveToNames } from './../../../../utils/constant';
import { merge, combineLatest } from 'rxjs';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
@Injectable({
	providedIn: 'root',
})
export class LeaveService {
	constructor(public afs: AngularFirestore) { }

	public addLeave(leaveDetails: any, vacation: any, hireDate: any) {
		return this.afs
			.collection('leaves')
			.add(leaveDetails)
			.then((doc) => {
				leaveDetails.message = `${leaveDetails.name} applied for ${leaveDetails.days} days of ${leaveDetails.type != 'other' ? leaveToNames[leaveDetails.category] : leaveDetails.category
					}(${leaveDetails.type}) leave from ${moment(leaveDetails.startDate).format('MM/DD/YYYY')} to ${moment(leaveDetails.endDate).format('MM/DD/YYYY')}
                ${leaveDetails.user_type != 'self' ? '(Applied by ' + leaveDetails.createdBy + ')' : ''}`;
				this.addLogs(leaveDetails);
				this.updateLeaves(leaveDetails, vacation, hireDate);
			});
	}

	/**
	 * updateLeaves
		leaves:any,vacation:any     */
	public updateLeaves(leaves: any, vacation: any, hireDate?: any) {
		const year = new Date(leaves.startDate).getFullYear();
		const { days } = leaves;
		let { availableTimeOff, balanceTimeOff } = vacation && vacation[year] ? vacation[year] : { availableTimeOff: 0, balanceTimeOff: 0 };
		if (leaves.status == 'Pending') {
			if (availableTimeOff && leaves.type == 'pto') {
				if (!balanceTimeOff) balanceTimeOff = availableTimeOff;
				balanceTimeOff = Number(balanceTimeOff) - Number(leaves.days);
				if (vacation[year][leaves.category]) {
					vacation[year][leaves.category] = Number(vacation[year][leaves.category]) + Number(leaves.days);
				} else {
					vacation[year][leaves.category] = Number(leaves.days);
				}
				vacation[year]['balanceTimeOff'] = balanceTimeOff;
			} else {
				vacation = vacation || {};
				if (!vacation[year]) {
					vacation[year] = {};
				}
				const vacations = vacation[year] ? vacation[year] : {};

				const leavesList = vacations[leaves.type] ? Number(vacations[leaves.type]) : 0;
				const addedleave = leavesList + Number(leaves.days);
				vacation[year][leaves.type] = addedleave;
			}
		} else {
			if (availableTimeOff && leaves.type == 'pto') {
				balanceTimeOff = Number(balanceTimeOff) + Number(leaves.days);
				if (vacation[year][leaves.category]) {
					vacation[year][leaves.category] = Number(vacation[year][leaves.category]) - Number(leaves.days);
				} else {
					vacation[year][leaves.category] = Number(leaves.days);
				}
				vacation[year]['balanceTimeOff'] = balanceTimeOff;
			} else {
				const vacations = vacation && vacation[year] ? vacation[year] : {};
				const leavesList = vacations[leaves.type] ? Number(vacations[leaves.type]) : 0;
				const addedleave = leavesList - Number(leaves.days);
				vacation[year][leaves.type] = addedleave;
			}
		}

		this.updateCrewVacation(leaves.crewId, vacation);
	}

	/**
	 * updateCrewVacation
		crewId,vacation     */
	public updateCrewVacation(id, vacation) {
		const crewData = this.afs.doc(`crewMembers/${id}`);
		return crewData.set({ vacation: vacation }, { merge: true });
	}

	public getLeaveById(id: string, from: string) {
		if (from === 'crew') {
			return this.afs.collection('leaves', (ref) => ref.where('crewId', '==', id).orderBy('createdDate', 'asc')).valueChanges({ idField: 'id' });
		} else {
			// Query without filtering by status
			return this.afs
				.collection('leaves', (ref) => ref.where('crewId', '==', id))
				.valueChanges({ idField: 'id' })
				.pipe(
					// Filter out rejected leaves programmatically
					map((leaves: any[]) => leaves.filter((leave) => leave.status !== 'Rejected'))
				);
		}
	}

	public getLeaveByYear(id, year) {
		return this.afs.collection('leaves', (ref) => ref.where('crewId', '==', id).where('year', '==', year).orderBy('createdDate', 'desc')).valueChanges({ idField: 'id' });
	}

	public getAllLeaves() {
		const pendingQuery = this.afs.collection('leaves', (ref) => ref.where('status', '==', 'Pending')).valueChanges();

		const approvedQuery = this.afs.collection('leaves', (ref) => ref.where('status', '==', 'Approved')).valueChanges();

		// Combine both queries
		return combineLatest([pendingQuery, approvedQuery]).pipe(map(([pending, approved]) => [...pending, ...approved]));
	}
	public getAllLeavesByYear(year: any, from: string) {
		if (from === 'timeOff') {
			return this.afs.collection('leaves', (ref) => ref.where('year', '==', year).orderBy('createdDate', 'desc')).valueChanges({ idField: 'id' });
		} else if (from === 'pending') {
			return this.afs
				.collection('leaves', (ref) => ref.where('year', '==', year).orderBy('createdDate', 'desc'))
				.valueChanges({ idField: 'id' })
				.pipe(
					// Filter out Rejected statuses programmatically
					map((leaves: any[]) => leaves.filter((leave) => leave.status !== 'Rejected'))
				);
		} else if (from === 'approved') {
			return this.afs
				.collection('leaves', (ref) => ref.where('year', '==', year).orderBy('createdDate', 'desc'))
				.valueChanges({ idField: 'id' })
				.pipe(
					// Filter out Rejected statuses programmatically
					map((leaves: any[]) => leaves.filter((leave) => leave.status == 'Approved'))
				);
		} else {
			return this.afs
				.collection('leaves', (ref) => ref.where('year', '==', year).orderBy('createdDate', 'desc'))
				.valueChanges({ idField: 'id' })
				.pipe(
					// Filter out Rejected statuses programmatically
					map((leaves: any[]) => leaves.filter((leave) => leave.status == 'Pending'))
				);
		}
	}

	/**
	 * addLogs
	 */
	public addLogs(logs: any) {
		const log = {
			crew: logs.crewId,
			name: logs.name,
			year: logs.year,
			date: moment(logs.createDate).format('MM/DD/YYYY,h:mm A'),
			message: logs.message,
			created_date: new Date().getTime(),
		};
		return this.afs.collection('leave_logs').add(log);
	}

	/**
	 * getAllLeaveLogs
	 */
	public getAllLeaveLogs(year: any) {
		return this.afs.collection('leave_logs', (ref) => ref.where('year', '==', year).orderBy('created_date', 'desc')).valueChanges();
	}
	/**
	 * updateRejectLeave
	leave     */
	public updateRejectLeave(leave: any, vacation: any) {
		return this.afs
			.doc(`leaves/${leave.id}`)
			.set({ status: leave.status, updatedBy: leave.updatedBy, updatedDate: leave.updatedDate }, { merge: true })
			.then((doc) => {
				this.updateLeaves(leave, vacation);
				this.addLogs(leave);
			});
	}

	/**
	 * updateApproveLeave
	leave     */
	public updateApproveLeave(leave) {
		return this.afs
			.doc(`leaves/${leave.id}`)
			.set({ status: leave.status, updatedBy: leave.updatedBy, updatedDate: leave.updatedDate }, { merge: true })
			.then((doc) => {
				this.addLogs(leave);
			});
	}

	public isLeaveTakenAlready(leave: any) {
		return this.afs
			.collection(
				'leaves',
				(ref) => ref.where('crewId', '==', leave.crewId).where('year', '==', leave.year)
				// No status filtering here due to Firestore limitations
			)
			.valueChanges({ idField: 'id' })
			.pipe(
				first(),
				map((leaves: any[]) =>
					// Filter out rejected leaves and check for overlapping dates
					leaves.filter(
						(existingLeave) =>
							existingLeave.status !== 'Rejected' && // Exclude rejected statuses
							existingLeave.startDate <= leave.endDate && // Check for overlapping dates
							existingLeave.endDate >= leave.startDate
					)
				)
			);
	}

	public getVacations(start: number, end: number) {
		return this.afs
			.collection('leaves')
			.valueChanges({ idField: 'id' })
			.pipe(
				first(),
				map((leaves: any[]) => {
					// Filter out rejected statuses client-side
					const filteredLeaves = leaves.filter(
						(existingLeave) => existingLeave.status !== 'Rejected' && existingLeave.startDate >= start && existingLeave.endDate <= end
					);
					return filteredLeaves;
				})
			);
	}
	public howmanyLeavesCanTake(hireDate: any, balanceLeave: any) {
		const today = new Date();
		const startDate = new Date(hireDate.seconds * 1000);

		today.setHours(0, 0, 0, 0);
		startDate.setHours(0, 0, 0, 0);

		const differenceInMs = today.getTime() - startDate.getTime();
		const days = differenceInMs / (1000 * 60 * 60 * 24);
		const years = days / 365;

		if (years < 1) {
			return 0;
		} else if (years >= 1 && years < 2) {
			return Number(balanceLeave) * 0.25;
		} else if (years >= 2 && years < 3) {
			return Number(balanceLeave) * 0.5;
		} else {
			return Number(balanceLeave);
		}
	}
}
