import { Injectable } from '@angular/core';
import { Observable, of, Subscription, BehaviorSubject } from 'rxjs';
import { User } from '../models/user.model';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { map, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	user: Observable<User>;
	userDetails: User;
	permissionsLoaded = new BehaviorSubject(false);
	permissions: any;

	constructor(public afs: AngularFirestore, private afAuth: AngularFireAuth, private router: Router, private permissionsService: NgxPermissionsService) {
		console.log('Auth Service Constructor');

		this.user = this.afAuth.authState.pipe(
			switchMap((userData: any) => {
				if (userData) {
					return this.afs
						.collection<User>('users', (ref) => ref.where('uid', '==', userData.uid))
						.snapshotChanges()
						.pipe(
							map((actions) => {
								const value = actions.map((action) => {
									const user: any = action.payload.doc.data() as User;
									user.emailVerified = userData.emailVerified;
									user.providers = userData.providerData;

									if (user.permissions) {
										this.permissionsService.loadPermissions(user.permissions);
									} else {
										this.permissionsService.loadPermissions(['guest']);
									}
									this.userDetails = user; //THIS NECESSARY?
									sessionStorage.setItem('user', user);
									sessionStorage.setItem('user_id', user.uid);
									sessionStorage.setItem('permissions', user.permissions);
									this.permissions = user.permissions;
									this.permissionsLoaded.next(true);

									return user;
								});

								return value[0];
							})
						);

					// commented on may 6 for auto updation of permissions
					// return this.afs
					// 	.doc<User>(`users/${userData.uid}`)
					// 	.ref.get()
					// 	.then((doc) => {
					// 		if (doc.exists) {
					// 			const user: any = doc.data() as User;
					// 			user.emailVerified = userData.emailVerified;
					// 			user.providers = userData.providerData;
					// 			if (user.permissions) {
					// 				this.permissionsService.loadPermissions(user.permissions);
					// 			} else {
					// 				this.permissionsService.loadPermissions(['guest']);
					// 			}
					// 			this.userDetails = user; //THIS NECESSARY?
					// 			sessionStorage.setItem('user_id', user.uid);
					// 			sessionStorage.setItem('permissions', user.permissions);
					// 			this.permissions = user.permissions;
					// 			this.permissionsLoaded.next(true);

					// 			return user;
					// 		}
					// 	})
					// 	.catch(function (error): void {
					// 		console.log('Error getting document:', error);
					// 	});
				} else {
					return of(null);
				}
			})
		);
	}

	reloadUserPermissions(userId): void {
		console.log('reload permissions');
		this.getLoggedInUser()
			.pipe(take(1))
			.subscribe((user: User) => {
				if (user.permissions) {
					this.permissionsService.loadPermissions(user.permissions);
				} else {
					this.permissionsService.loadPermissions(['guest']);
				}
			});
	}

	getLoggedInUser(): Observable<User> {
		// return this.user;
		this.user.pipe(take(1)).subscribe((user: User) => {
			if (user.emailVerified) {
				this.afs
					.doc<User>(`users/${userId}`)
					.ref.get()
					.then((doc) => {
						if (!doc.data().emailVerified) {
							this.afs.doc<User>(`users/${userId}`).update({ emailVerified: true });
						}
					});
			}
		});
		const userId = sessionStorage.getItem('user_id');

		if (userId) {
			return this.afs.doc<User>(`users/${userId}`).valueChanges();
		}
	}

	reloadAuthUser(): void {
		console.log(this.user);
	}

	updateUser(user): Promise<void> {
		const userId = sessionStorage.getItem('user_id');
		if (userId) {
			return this.afs
				.doc<User>(`users/${userId}`)
				.update(user)
				.then(() => {
					this.reloadUserPermissions(userId);
				});
		} else {
			return Promise.reject('No user ID found!');
		}
	}

	emailSignUp(form): Promise<User> {
		console.log('​AuthService -> emailSignUp -> form', form);
		return this.afAuth.auth.createUserWithEmailAndPassword(form.email, form.password).then((user: any) => {
			const userData: User = { firstname: form.firstname, lastname: form.lastname, email: form.email, uid: user.user.uid, photoURL: '' };
			return this.createUserData(userData, false);
		});
	}

	emailLogin(email, password): Promise<auth.UserCredential> {
		return this.afAuth.auth.signInWithEmailAndPassword(email, password);
	}

	forgottenPassword(email): Promise<void> {
		const fbAuth = this.afAuth.auth;
		return fbAuth.sendPasswordResetEmail(email);
	}

	resetPassword(password): Promise<void> {
		const currentUser = this.afAuth.auth.currentUser;
		return currentUser.updatePassword(password);
	}

	logout(): Promise<void> {
		return this.afAuth.auth.signOut();
	}

	googleLogin() {
		const provider = new auth.GoogleAuthProvider();
		return this.oAuthLogin(provider);
	}

	private oAuthLogin(provider) {
		return this.afAuth.auth.signInWithPopup(provider).then((credential: any) => {
			// SET USER FIRSTNAME AND SURNAME
			credential.user.firstname = credential.additionalUserInfo.profile.given_name;
			credential.user.surname = credential.additionalUserInfo.profile.family_name;

			const userRef = this.afs.doc(`users/${credential.user.uid}`);

			return userRef
				.snapshotChanges()
				.pipe(take(1))
				.toPromise()
				.then((snap) => {
					if (snap.payload.data()) {
						// IF USER EXISTS UPDATE USER WITH GOOGLE DETAILS
						const userData = snap.payload.data();
						this.updateUserData(userData);
					} else {
						// IF USER DOES NOT EXIST CREATE USER WITH GOOGLE DETAILS
						return this.createUserData(credential.user, true);
					}
				})
				.catch((error) => {
					console.log('error', error);
				});
		});
	}

	private updateUserData(user) {
		const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);

		const userDetailsResult = userRef.set(
			{
				id: user.uid,
				email: user.email,
				firstname: user.firstname,
				lastname: user.lastname,
				photoURL: user.photoURL,
				emailVerified: true,
				socialLogin: true,
			},
			{ merge: true }
		);

		return Promise.all([userDetailsResult]);
	}

	private createUserData(user: User, socialLogin: boolean): Promise<User> {
		const userRef: AngularFirestoreDocument<User> = this.afs.doc(`users/${user.uid}`);
		let photoURL: string = user.photoURL;
		// SET USER DATA
		let userData: any = {};

		if (socialLogin === true) {
			userData = {
				active: true,
				id: user.uid,
				email: user.email,
				firstname: user.firstname,
				lastname: user.surname,
				photoURL: photoURL,
				emailVerified: true,
				socialLogin: true,
				permissions: ['admin'],
				created: Date.now(),
			};
		} else {
			userData = {
				active: true,
				id: user.uid,
				email: user.email,
				firstname: user.firstname,
				lastname: user.lastname,
				photoURL: photoURL,
				emailVerified: false,
				socialLogin: false,
				permissions: ['admin'],
				created: Date.now(),
			};
		}

		return userRef.set(userData, { merge: true }).then(() => {
			return user;
		});
	}
}
