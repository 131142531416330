import { AuthService } from './../../../auth/services/auth.service';
import { JobCrewMember } from './job.model';
import { PlannedDay } from './job.model';
import { Job } from '../services/job.model';
import { Injectable } from '@angular/core';
import { AngularFirestore, Query } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { TimeFrameService } from '../../reports/services/timeframe.service';

@Injectable({
	providedIn: 'root',
})
export class JobsService {
	constructor(public afs: AngularFirestore, private authService: AuthService, private timeframeService: TimeFrameService) {}

	async addJob(jobData, crewMembers): Promise<any> {
		try {
			const { id } = await this.afs.collection('jobs').add(jobData);
			if (crewMembers.length > 0) {
				await this.addCrewMembersToJob(id, crewMembers);
			}
			await this.addJobLog(id, 'New job created');
		} catch (err) {
			return Promise.reject(err);
		}
	}

	getPublicHolidays(year: string) {
		//Dont't add holidays which falls in weekends beacuse then it will be counted twice in time-off planner
		const holidays = [
			'01/01/' + year,
			// '02/08/' + year,
			'05/26/' + year,
			'07/04/' + year,
			'09/01/' + year,
			'11/27/' + year,
			// '11/29/' + year,
			'12/25/' + year,
			// '01/01/' + (parseInt(year) + 1),
		];
		const changedDays = [];
		holidays.forEach((holiday) => {
			const date = new Date(holiday);
			let newDate: Date;
			if (date.getDay() === 0 || date.getDay() === 6) {
				if (date.getDay() === 0) {
					newDate = new Date(date.setDate(date.getDate() + 1));
				} else if (date.getDay() === 6) {
					newDate = new Date(date.setDate(date.getDate() - 1));
				}
				var dd = String(newDate.getDate()).padStart(2, '0');
				var mm = String(newDate.getMonth() + 1).padStart(2, '0');
				var yyyy = newDate.getFullYear();
				changedDays.push(`${mm}/${dd}/${yyyy}`);
			}
		});
		const publicHolidays = holidays.concat(changedDays);
		return publicHolidays;
	}

	genLists() {}

	duplicateJob(jobId) {
		return this.afs
			.doc(`jobs/${jobId}`)
			.ref.get()
			.then((jobData) => {
				const data = jobData.data();
				data.locationName = data.locationName + ' (Duplicate)';
				this.addJobLog(jobId, 'Job Details Duplicated');
				return this.afs
					.collection('jobs')
					.add(data)
					.then((result) => {
						return Promise.resolve(result.id);
					});
			});
	}

	async updateJob(jobId, jobData): Promise<any> {
		try {
			return await this.afs.collection('jobs').doc(jobId).set(jobData, { merge: true });
		} catch (error) {
			return Promise.reject(error);
		}
	}

	updatePlannedDay(dayId, dayData, from): Promise<any> {
		return this.afs
			.collection('plannedDays')
			.doc(dayId.id)
			.set(dayData, { merge: true })
			.then((result) => {
				return this.addPlandaysLog(dayId, 'Planday is updated with below details from ' + from + '....' + JSON.stringify(dayData.payRollInfo));
			});
	}

	getJob(jobId: string): Observable<{}> {
		return this.afs.doc(`jobs/${jobId}`).valueChanges();
	}

	getJobOnce(jobId: string) {
		return this.afs
			.doc(`jobs/${jobId}`)
			.ref.get()
			.then((jobData) => {
				return jobData.data();
			});
	}

	getJobs(status?, plannerJobs: boolean = false, condtion: any = 'jobPlanner'): Observable<{}[]> {
		let jobRefQuery = this.afs.collection(`jobs`, (ref) => {
			let refQuery = ref.where('active', '==', true);
			if (plannerJobs) {
				refQuery = refQuery.orderBy('startDate', 'asc');
			} else {
				if (status) {
					refQuery = refQuery.where('status', '==', status);
				}
				if (status && status === 'Completed') {
					refQuery = refQuery.orderBy('completedDate', 'desc');
				} else {
					refQuery = refQuery.orderBy('created', 'desc');
				}
			}
			return refQuery;
		});

		return jobRefQuery.snapshotChanges().pipe(
			map((changes) => {
				return changes.map(({ payload: { doc } }) => {
					const data = { ...(doc.data() as Job), id: doc.id };

					if (!data.hasOwnProperty('newClientColor')) {
						if (!data.hasOwnProperty('clientColor')) data.clientColor = '#eb7724';
					} else {
						data.clientColor = data.newClientColor;
					}
					data.darkerBg = this.LightenDarkenColor(data.clientColor, -20);

					['startDate', 'endDate'].forEach((field) => {
						if (data[field]) {
							const tempDate = data[field]['seconds'];
							data[`${field}Text`] = tempDate ? moment.unix(tempDate).format('dddd MMM D YYYY') : '';
						}
					});
					return data;
				});
			})
		);
	}

	getJobsForGanttChart(filterOptions: Job) {
		let jobRefQuery = this.afs.collection<Job>('jobs').ref.where('active', '==', true).where('ganttChart', '==', true);
		if (filterOptions.locationName) {
			jobRefQuery = jobRefQuery.where('locationName', '==', filterOptions.locationName);
		}
		if (filterOptions.jobNumber) {
			jobRefQuery = jobRefQuery.where('jobNumber', '==', filterOptions.jobNumber);
		}
		if (filterOptions.clientName) {
			jobRefQuery = jobRefQuery.where('clientName', '==', filterOptions.clientName);
		}
		if (filterOptions.startDate) {
			jobRefQuery = jobRefQuery.where('startDate', '>=', filterOptions.startDate);
		}
		jobRefQuery = jobRefQuery.orderBy('startDate', 'asc');
		return jobRefQuery.get();
	}

	getAllJobs() {
		return this.afs.collection('jobs', (ref) => ref.where('active', '==', true).orderBy('locationName', 'asc')).valueChanges({ idField: 'id' });
	}

	getJobStats() {
		return this.afs.doc('utils/stats').valueChanges();
	}

	removeJob(jobId) {
		return this.afs.doc(`jobs/${jobId}`).set({ active: false }, { merge: true });
	}

	getCompletedJobsForPeriod(startDate, endDate): Observable<{}[]> {
		return this.afs
			.collection('jobs', (ref) =>
				ref.where('completedDate', '>=', startDate).where('completedDate', '<=', endDate).where('status', '==', 'Completed').where('active', '==', true)
			)
			.snapshotChanges()
			.pipe(
				map((changes) => {
					return changes.map((a) => {
						const data = a.payload.doc.data() as Job;
						data.id = a.payload.doc.id;
						const startDateTemp = data.startDate['seconds'];
						data.startDateText = moment.unix(startDateTemp).format('MMMM YYYY');
						return data;
					});
				})
			);
	}

	getJobsForPeriod(startDate, endDate): Observable<{}[]> {
		return this.afs
			.collection('jobs', (ref) => ref.where('startDate', '>=', startDate).where('startDate', '<=', endDate).where('active', '==', true))
			.snapshotChanges()
			.pipe(
				map((changes) => {
					return changes.map((a) => {
						const data = a.payload.doc.data() as Job;
						data.id = a.payload.doc.id;
						const startDateTemp = data.startDate['seconds'];
						data.startDateText = moment.unix(startDateTemp).format('MMMM YYYY');
						return data;
					});
				})
			);
	}

	getJobsForPeriodBasedOnStatus(startDate, endDate, status): Observable<{}[]> {
		return this.afs
			.collection('jobs', (ref) => ref.where('startDate', '>=', startDate).where('startDate', '<=', endDate).where('active', '==', true).where('status', '==', status))
			.snapshotChanges()
			.pipe(
				map((changes) => {
					return changes.map((a) => {
						const data = a.payload.doc.data() as Job;
						data.id = a.payload.doc.id;
						const startDateTemp = data.startDate['seconds'];
						data.startDateText = moment.unix(startDateTemp).format('MMMM YYYY');
						return data;
					});
				})
			);
	}

	getJobsForClient(clientId: string): Observable<{}[]> {
		return this.afs
			.collection('jobs', (ref) => ref.where('clientId', '==', clientId))
			.snapshotChanges()
			.pipe(
				map((changes) => {
					return changes.map((a) => {
						const data = a.payload.doc.data() as Job;
						data.id = a.payload.doc.id;
						const startDateTemp = data.startDate['seconds'];
						data.startDateText = moment.unix(startDateTemp).format('MMMM YYYY');
						return data;
					});
				})
			);
	}

	getShifts(): Observable<{}[]> {
		return this.afs
			.collection('jobs', (ref) => ref.orderBy('created', 'desc').where('active', '==', true))
			.snapshotChanges()
			.pipe(
				map((changes) => {
					return changes.map((a: any) => a.payload.doc.data().shift);
				})
			);
	}

	getUnique(arr) {
		const final = [];

		arr.map((e, i) => !final.includes(e) && final.push(e));

		return final;
	}

	addCrewMembersToJob(jobId, crewMembers) {
		const crewMembersRef = this.afs.collection('jobs').doc(`${jobId}`).collection('crewMembers');
		const crewMemberPromiseList = [];
		crewMembers.forEach((crewMember) => {
			const jobCrewData: JobCrewMember = {
				id: crewMember.id,
				created: new Date(),
				active: true,
				confirmed: false,
				firstname: crewMember.firstname,
				lastname: crewMember.lastname,
				type: crewMember.type,
				color: crewMember.color,
			};
			crewMemberPromiseList.push(crewMembersRef.doc(crewMember.id).set(jobCrewData, { merge: true }));
		});
		return Promise.all(crewMemberPromiseList).then(() => {
			return Promise.resolve();
		});
	}

	addCrewMemberToJob(jobId, crewMember) {
		const crewMembersRef = this.afs.collection('jobs').doc(`${jobId}`).collection('crewMembers');
		const { firstname, lastname, type, cell, color } = crewMember;
		const jobCrewData: JobCrewMember = {
			id: crewMember.id,
			created: new Date(),
			active: true,
			confirmed: false,
			firstname,
			lastname,
			type: type || '',
			cell,
			color,
		};
		return crewMembersRef
			.doc(crewMember.id)
			.set(jobCrewData, { merge: true })
			.then(() => {
				return this.addJobLog(jobId, `Added ${crewMember.firstname} ${crewMember.lastname} to job`);
			});
	}

	LightenDarkenColor(col, amt) {
		let color = col;
		if (color.includes('rgb') || color.includes('rgba')) {
			color = this.RGBToHex(color);
		}
		var usePound = false;

		if (color[0] == '#') {
			color = color.slice(1);
			usePound = true;
		}

		var R = parseInt(color.substring(0, 2), 16);
		var G = parseInt(color.substring(2, 4), 16);
		var B = parseInt(color.substring(4, 6), 16);

		// to make the colour less bright than the input
		// change the following three "+" symbols to "-"
		R = R + amt;
		G = G + amt;
		B = B + amt;

		if (R > 255) R = 255;
		else if (R < 0) R = 0;

		if (G > 255) G = 255;
		else if (G < 0) G = 0;

		if (B > 255) B = 255;
		else if (B < 0) B = 0;

		var RR = R.toString(16).length == 1 ? '0' + R.toString(16) : R.toString(16);
		var GG = G.toString(16).length == 1 ? '0' + G.toString(16) : G.toString(16);
		var BB = B.toString(16).length == 1 ? '0' + B.toString(16) : B.toString(16);
		const final = (usePound ? '#' : '') + RR + GG + BB;
		return final;
	}

	RGBToHex(rgb) {
		// Choose correct separator
		let sep = rgb.indexOf(',') > -1 ? ',' : ' ';
		// Turn "rgb(r,g,b)" into [r,g,b]
		rgb = rgb.substr(5).split(')')[0].split(sep);

		let r = (+rgb[0]).toString(16),
			g = (+rgb[1]).toString(16),
			b = (+rgb[2]).toString(16);

		if (r.length == 1) r = '0' + r;
		if (g.length == 1) g = '0' + g;
		if (b.length == 1) b = '0' + b;

		return '#' + r + g + b;
	}

	getAllAssignedJobandShiftTimes(jobId: string) {
		//   const plannedDays =  this.afs.collection('plannedDays').ref.where('jobId', '==', jobId).get();
		// 		return plannedDays

		// return this.afs.collection('plannedDays').where('jobId', '==', jobId)
		return this.afs
			.collection('plannedDays', (ref) => ref.where('jobId', '==', jobId))
			.snapshotChanges()
			.pipe(map((changes) => this.getUniquePlannedDays(changes)));
	}

	getCurrentCrewMembersConfirmedPerJob(jobsData) {
		// const promiseList = [];
		// const confirmedData = {};

		// jobsData.forEach((job: any) => {
		//     this.getCrewMembersForJob(job.id).subscribe(jobCrewMembers => {
		//         if (!confirmedData.hasOwnProperty(job.Id)) {
		//             confirmedData[job.id] = 0;
		//         }
		//         jobCrewMembers.forEach((crewMember: any) => {
		//             if (crewMember.confirmed) {
		//                 confirmedData[job.id] = confirmedData[job.id] + 1;
		//             }
		//         });
		//     });
		// });

		// return confirmedData;

		const plannedDaysRef = this.afs.collection('plannedDays').ref;
		const queryref = plannedDaysRef.where('active', '==', true);
		return queryref
			.get()
			.then((snapshot) => {
				if (!snapshot.empty) {
					const confirmedData = {};
					const cleanData = this.getUniquePlannedDays(snapshot.docs, 1);
					cleanData.forEach((doc) => {
						const tmpData = doc;

						if (!confirmedData.hasOwnProperty(tmpData.jobId)) {
							confirmedData[tmpData.jobId] = 0;
						}

						if (tmpData.crewId !== '' && tmpData.crewId !== '1') {
							if (tmpData.hasOwnProperty('type')) {
								if (tmpData.type !== 'other' && tmpData.type !== 'driver') {
									if (tmpData.confirmed) {
										confirmedData[tmpData.jobId] = confirmedData[tmpData.jobId] + 1;
									}
								}
							} else {
								if (tmpData.confirmed) {
									confirmedData[tmpData.jobId] = confirmedData[tmpData.jobId] + 1;
								}
							}
						}
					});

					return Promise.resolve(confirmedData);
				} else {
					return Promise.resolve([]);
				}
			})
			.catch((error) => {
				return Promise.reject('Could not get completed jobs list' + error);
			});
	}

	getCrewMembersForJob(jobId: string): Observable<{}[]> {
		return this.afs
			.collection(`jobs/${jobId}/crewMembers`, (ref) => ref.where('active', '==', true).orderBy('firstname', 'desc'))
			.snapshotChanges()
			.pipe(
				map((changes) => {
					return changes.map((a) => {
						const data = a.payload.doc.data() as JobCrewMember;
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}

	removeCrewMemberFromJob(jobId, crewMemberId, crewMember) {
		if (crewMemberId) {
			return this.afs
				.collection('jobs')
				.doc(jobId)
				.collection('crewMembers')
				.doc(crewMemberId)
				.delete()
				.then(() => {
					return this.addJobLog(jobId, `Removed ${crewMember.firstname} ${crewMember.lastname} from job`);
				});
		} else {
			return Promise.reject('Crew Id not specified');
		}
	}

	updateConfirmedStatusForCrewMemberForJob(jobId, crewMemberId, confirmed, crewMember): Promise<any> {
		return this.afs
			.collection('jobs')
			.doc(jobId)
			.collection('crewMembers')
			.doc(crewMemberId)
			.set({ confirmed: confirmed }, { merge: true })
			.then((result) => {
				return this.afs
					.doc(`crewMembers/${crewMemberId}/jobs/${jobId}`)
					.set({ confirmed: confirmed }, { merge: true })
					.then(() => {
						return this.addJobLog(
							jobId,
							`${crewMember.firstname} ${crewMember.lastname} updated availability of job to  : ` + (confirmed === true ? 'available' : 'unavailable')
						);
					});
			});
	}

	getJobLog(jobId: string): Observable<{}[]> {
		return this.afs.collection(`jobs/${jobId}/log`, (ref) => ref.orderBy('created', 'desc')).valueChanges();
	}

	addJobLog(jobId: string, message: any): Promise<any> {
		const logData = {
			message: message,
			firstname: this.authService.userDetails.firstname,
			lastname: this.authService.userDetails.lastname,
			userId: this.authService.userDetails.id,
			created: new Date(),
		};
		return this.afs
			.collection(`jobs/${jobId}/log`)
			.add(logData)
			.then((data) => {
				return data;
			});
	}

	addPlandaysLog(job: any, message: any): Promise<any> {
		return this.afs
			.collection('logs')
			.add({
				jobId: job.jobId,
				message: message,
				shift: job.shift,
				plandayId: job.id,
				date: new Date().toLocaleString().split(',')[0],
				firstname: this.authService.userDetails.firstname,
				lastname: this.authService.userDetails.lastname,
				userId: this.authService.userDetails.id,
				crewId: job.crewId,
				jobDate: new Date(job.date).toLocaleString().split(',')[0],
				currentTime: new Date().toUTCString(),
			})
			.then((data) => {
				return data;
			});
	}
	// REPORTS
	getDaysForClient(clientId: string) {
		return this.afs
			.collection('plannedDays', (ref) => ref.where('clientId', '==', clientId).where('crewId', '>', '').where('active', '==', true))
			.snapshotChanges()
			.pipe(map((changes) => this.getUniquePlannedDays(changes)));
	}

	getTotalDaysForJob(jobId: string) {
		if (jobId) {
			return this.afs
				.collection('plannedDays', (ref) => ref.where('jobId', '==', jobId).where('crewId', '>', '1').where('active', '==', true))
				.snapshotChanges()
				.pipe(map((changes) => this.getUniquePlannedDays(changes)));
		}
	}

	getTotalDaysForJobInTimeframe(timeframe: string, year: string, jobId: string) {
		const { startDate, endDate } = this.timeframeService.getTimeFrame(timeframe, year, false);

		return this.afs
			.collection('plannedDays', (ref) => ref.where('jobId', '==', jobId).where('date', '>=', startDate).where('date', '<=', endDate).where('active', '==', true))
			.snapshotChanges()
			.pipe(map((changes) => this.getUniquePlannedDays(changes)));
	}

	getDaysForCrewMembers(startDate, endDate) {
		return this.afs
			.collection('plannedDays', (ref) => ref.where('date', '>=', startDate).where('date', '<=', endDate).where('active', '==', true))
			.snapshotChanges()
			.pipe(map((changes) => this.getUniquePlannedDays(changes)));
	}

	getScheduledDaysForForTimePeriod(startDate, endDate) {
		return this.afs
			.collection('plannedDays', (ref) => ref.where('date', '>=', startDate).where('date', '<=', endDate).where('active', '==', true))
			.snapshotChanges()
			.pipe(map((changes) => this.getUniquePlannedDays(changes)));
	}

	getTotalDaysForJobOnce(jobId: string) {
		const plannedDaysRef = this.afs.collection('plannedDays').ref;
		const queryref = plannedDaysRef.where('jobId', '==', jobId).where('crewId', '>', '1').where('active', '==', true);
		return queryref
			.get()
			.then((snapshot) => {
				if (!snapshot.empty) {
					const plannedDaysList = [];
					const cleanData = this.getUniquePlannedDays(snapshot.docs, 1);
					cleanData.forEach((doc) => {
						const tmpData = doc;
						if (tmpData.hasOwnProperty('type')) {
							if (tmpData.type !== 'other' && tmpData.type !== 'driver') {
								plannedDaysList.push(tmpData);
							}
						} else {
							plannedDaysList.push(tmpData);
						}
					});
					return Promise.resolve(plannedDaysList);
				} else {
					return Promise.resolve([]);
				}
			})
			.catch((error) => {
				return Promise.reject('Could not get completed jobs list once' + error);
			});
	}

	async addCrewMemberCurrentToShift(selectedData: any, crewData: any) {
		debugger;
		const {
			id: jobId,
			jobNumber,
			jobColor,
			clientId,
			clientName,
			clientColor,
			location,
			locationName,
			city,
			state,
			outOfTown,
			startDate: jobStartDate,
			endDate: jobEndDate,
			status: jobStatus,
			requestConfirmation,
		} = crewData;

		const data: PlannedDay = {
			jobId,
			jobNumber,
			jobColor,
			clientId,
			clientName,
			clientColor: clientColor || '',
			location,
			locationName,
			city,
			state,
			outOfTown,
			date: crewData.day,
			crewId: crewData.crewId,
			shift: crewData.shift,
			crewFirstName: crewData.crewFirstName,
			crewLastName: crewData.crewLastName,
			crewColor: crewData.crewColor,
			crewTextColor: selectedData.tierTextColor,
			active: true,
			jobActive: true,
			dayConfirmed: crewData.dayConfirmed,
			jobStartDate: crewData.startDate,
			jobEndDate: crewData.endDate,
			jobStatus: crewData.status,
		};
		try {
			await this.addJobLog(jobId, data.crewFirstName + ' ' + data.crewLastName + ' was added to Shift: ' + data.shift + ' for day ' + moment(data.date).format('MM/DD/YYYY'));
			if (requestConfirmation) {
				const crewJobRef = await this.afs.collection('jobs').doc(jobId).collection('crewMembers').ref.where('id', '==', data.crewId).get();
				if (crewJobRef.empty) {
					const crewRef = await this.afs.collection('crewMembers').doc(data.crewId).ref.get();
					if (crewRef.exists) {
						await this.addCrewMemberToJob(jobId, crewRef.data());
					}
				}
			}
			return this.afs.collection('plannedDays').add(data);
		} catch (err) {
			console.log(err);
		}
	}

	async addCrewMemberToShift(
		job,
		day,
		shift,
		crewId,
		crewFirstName,
		crewLastName,
		crewColor,
		crewTextColor,
		personType,
		shiftStart?,
		shiftEnd?,
		shiftName?,
		timeDuration?,
		shiftStartDate?,
		shiftEndDate?,
		cell?,
		countryCode?,
		union?,
		level?,
		isNewPayroll?
	) {
		const {
			id: jobId,
			jobNumber,
			jobColor,
			clientId,
			clientName,
			clientColor,
			location,
			locationName,
			city,
			state,
			outOfTown,
			startDate: jobStartDate,
			endDate: jobEndDate,
			status: jobStatus,
			requestConfirmation,
		} = job;
		const dayConfirmed = requestConfirmation ? requestConfirmation : false;
		const data: PlannedDay = {
			jobId,
			jobNumber,
			jobColor,
			clientId,
			clientName,
			clientColor: clientColor || '',
			location,
			locationName,
			city,
			state,
			outOfTown,
			date: day,
			shift: shift,
			crewId: crewId,
			crewFirstName: crewFirstName,
			crewLastName: crewLastName,
			crewColor: crewColor,
			crewTextColor: crewTextColor,
			active: true,
			jobActive: true,
			dayConfirmed,
			confirmed: false,
			denied: false,
			jobStartDate,
			jobEndDate,
			jobStatus,
			type: personType || '',
			shiftEnd: shiftEnd ? shiftEnd : '',
			shiftStart: shiftStart ? shiftStart : '',
			shiftName: shiftName ? shiftName : '',
			TimeDuration: timeDuration ? timeDuration : '',
			shiftStartDate: shiftStartDate ? shiftStartDate : '',
			shiftEndDate: shiftEndDate ? shiftEndDate : '',
			cell: cell ? cell : '',
			countryCode: countryCode ? countryCode : '',
			createdOn: new Date(),
			union: union ? union : '',
			level: level ? level : '',
			isNewPayroll: isNewPayroll ? isNewPayroll : true,
		};

		try {
			await this.addJobLog(jobId, crewFirstName + ' ' + crewLastName + ' was added to Shift: ' + shift + ' for day ' + moment(day).format('MM/DD/YYYY'));
			if (requestConfirmation) {
				const crewJobRef = await this.afs.collection('jobs').doc(jobId).collection('crewMembers').ref.where('id', '==', crewId).get();
				if (crewJobRef.empty) {
					const crewRef = await this.afs.collection('crewMembers').doc(crewId).ref.get();
					if (crewRef.exists) {
						await this.addCrewMemberToJob(jobId, crewRef.data());
					}
				}
			}
			return this.afs.collection('plannedDays').add(data);
		} catch (err) {
			console.log(err);
			return false;
		}
	}

	addShiftToDayForJob(shift: any, job: any, date: any) {
		const data: PlannedDay = {
			jobId: job.id,
			jobNumber: job.jobNumber,
			jobColor: job.jobColor,
			jobStartDate: job.startDate,
			jobEndDate: job.endDate,
			clientId: job.clientId,
			clientName: job.clientName,
			clientColor: job.clientColor,
			location: job.location,
			city: job.city,
			date: new Date(date),
			TimeDuration: typeof shift.timeDuration === 'undefined' ? null : shift.timeDuration,
			TimeDurationNew: typeof shift.newTimeDuration === 'undefined' ? null : shift.newTimeDuration,
			shift: typeof shift.shiftTime === 'undefined' ? shift : shift.shiftTime,
			shiftName: typeof shift.shiftName === 'undefined' ? '' : shift.shiftName ? shift.shiftName : shift.customName,
			shiftStart: typeof shift.shiftStart === 'undefined' ? '' : shift.shiftStart,
			shiftEnd: typeof shift.shiftEnd === 'undefined' ? '' : shift.shiftEnd,
			crewId: '',
			crewFirstName: '',
			crewLastName: '',
			crewColor: '',
			active: true,
			jobCompleted: false,
			dayConfirmed: false,
			jobActive: job.active,
			jobStatus: job.status,
			shiftStartDate: shift.shiftStartDate,
			shiftEndDate: shift.shiftEndDate,
			createdOn: new Date(),
		};

		return this.afs
			.collection('plannedDays')
			.add(data)
			.then((doc) => {
				this.addJobLog(job.id, 'Shift ' + data.shift + ' was added on ' + date);
				return doc.id;
			});
	}

	addShiftNameToList(shiftName: string) {
		this.afs.collection('shifts').add({ name: shiftName });
	}

	getShiftList() {
		return this.afs.collection('shifts', (ref) => ref.orderBy('name', 'asc')).valueChanges();
	}

	copyShiftFromDayToDay(shift: any, jobId: any, currentDay: any, newDay: any) {
		const start = new Date(currentDay);
		start.setHours(0, 0, 0, 0);
		const end = new Date(currentDay);
		end.setHours(23, 59, 59, 999);
		//Checking for over night shift
		var startTime = this._convertInto24Hours(shift.split('to')[0]);
		var endTime = this._convertInto24Hours(shift.split('to')[1]);

		const jobDayShiftCrewRef = this.afs
			.collection('plannedDays')
			.ref.where('jobId', '==', jobId)
			.where('shift', '==', shift)
			.where('date', '>=', start)
			.where('date', '<=', end);
		return jobDayShiftCrewRef
			.get()
			.then((docs) => {
				docs.forEach((doc) => {
					const tmpData = doc.data();
					tmpData.date = newDay;
					tmpData['payRollInfo'] = {};
					tmpData['clockInTime'] = '';
					tmpData['clockInLateBy'] = '';
					tmpData['clockOutLateBy'] = '';
					tmpData['clockOutEarlyBy'] = '';
					tmpData['clockInEarlyBy'] = '';
					tmpData['clockOutTime'] = '';
					tmpData['shiftEndAlert'] = '';
					tmpData['shiftStartAlert'] = '';
					tmpData['createdOn'] = new Date();
					const newDate = new Date(newDay);
					let sTime = this._convertInto24Hours(shift.split('to')[0]);
					let eTime = this._convertInto24Hours(shift.split('to')[1].trim());

					if (sTime > eTime) {
						let shiftStartDate = this.setDateTime(newDate, shift.split('to')[0]);
						let shiftEndDate = this.setDateTime(newDate.setDate(newDate.getDate() + 1), shift.split('to')[1].trim());
						tmpData['shiftEndDate'] = shiftEndDate;
						tmpData['shiftStartDate'] = shiftStartDate;
					} else {
						let shiftStartDate = this.setDateTime(newDate, shift.split('to')[0]);
						let shiftEndDate = this.setDateTime(newDate, shift.split('to')[1].trim());
						tmpData['shiftEndDate'] = shiftEndDate;
						tmpData['shiftStartDate'] = shiftStartDate;
					}
					this.addJobLog(jobId, moment(currentDay).format('MM/DD/YYYY') + ' Shift ' + shift + ' was copied to ' + moment(newDay).format('MM/DD/YYYY'));
					this.afs.collection('plannedDays').add(tmpData);
				});
			})
			.catch(function (error) {
				console.log('Error getting document:', error);
			});
	}

	getShiftsForCurrentPeriod(startDate, endDate): Observable<{}[]> {
		const tmpStartDay = new Date(startDate);
		tmpStartDay.setHours(0, 0, 0, 0);
		const tmpEndDay = new Date(endDate);
		tmpEndDay.setHours(23, 59, 59, 999);
		return this.afs
			.collection('plannedDays', (ref) => ref.where('date', '>=', tmpStartDay).where('date', '<=', tmpEndDay).where('active', '==', true).orderBy('date', 'asc'))
			.snapshotChanges()
			.pipe(map((changes) => this.getUniquePlannedDays(changes)));
	}

	getShiftsForPlannedDays(startDate, endDate): Observable<{}[]> {
		const tmpStartDay = new Date(startDate);
		tmpStartDay.setHours(0, 0, 0, 0);
		const tmpEndDay = new Date(endDate);
		tmpEndDay.setHours(23, 59, 59, 999);
		console.log(tmpStartDay, tmpEndDay);
		return this.afs
			.collection('plannedDays', (ref) => ref.where('date', '>=', tmpStartDay).where('date', '<=', tmpEndDay).where('active', '==', true).orderBy('date', 'asc'))
			.valueChanges({ idField: 'id' });
	}

	getConfirmedShiftsForCurrentPeriod(startDate, endDate): Observable<{}[]> {
		const tmpStartDay = new Date(startDate);
		tmpStartDay.setHours(0, 0, 0, 0);
		const tmpEndDay = new Date(endDate);
		tmpEndDay.setHours(23, 59, 59, 999);

		return this.afs
			.collection('plannedDays', (ref) =>
				ref
					.where('date', '>=', tmpStartDay)
					.where('date', '<=', tmpEndDay)
					.where('active', '==', true)
					.where('confirmed', '==', true)
					.orderBy('date', 'asc')
					.orderBy('crewFirstName', 'asc')
			)
			.snapshotChanges()
			.pipe(map((changes) => this.getUniquePlannedDays(changes)));
	}

	getCompletedShiftsForCurrentPeriod(startDate, endDate): Observable<{}[]> {
		const tmpStartDay = new Date(startDate);
		tmpStartDay.setHours(0, 0, 0, 0);
		const tmpEndDay = new Date(endDate);
		tmpEndDay.setHours(23, 59, 59, 999);
		return this.afs
			.collection('plannedDays', (ref) =>
				ref.where('date', '>=', tmpStartDay).where('date', '<=', tmpEndDay).where('active', '==', true).where('jobStatus', '==', 'Completed').orderBy('date', 'asc')
			)
			.snapshotChanges()
			.pipe(map((changes) => this.getUniquePlannedDays(changes)));
	}

	getUniquePlannedDays(changes, type = 0) {
		const unique = [];
		changes.map((x) => {
			let tmpData;
			if (type === 1) {
				tmpData = x.data() as PlannedDay;
			} else if (type === 0) {
				tmpData = x.payload.doc.data() as PlannedDay;
				tmpData.id = x.payload.doc.id;
			} else {
				tmpData = x;
			}

			if (tmpData && tmpData['date']) {
				unique.filter(
					(a) =>
						a.jobId === tmpData.jobId &&
						a.shift === tmpData.shift &&
						a.crewId === tmpData.crewId &&
						// tslint:disable-next-line: no-unused-expression
						a['date']['seconds'] === tmpData['date']['seconds']
				).length > 0
					? null
					: unique.push(tmpData);
			}
		});

		return unique;
	}

	getTotalManDaysForJobs(): Observable<{}[]> {
		return this.afs
			.collection('plannedDays')
			.snapshotChanges()
			.pipe(map((changes) => this.getUniquePlannedDays(changes)));
	}

	removeCrewMemberFromJobShiftDay(crewId: string, jobId: string, shift: string, day: any, crewFirstName: string, crewLastName: string) {
		const start = new Date(day);
		start.setHours(0, 0, 0, 0);
		const end = new Date(day);
		end.setHours(23, 59, 59, 999);

		const jobDayShiftCrewRef = this.afs
			.collection('plannedDays')
			.ref.where('crewId', '==', crewId)
			.where('jobId', '==', jobId)
			.where('shift', '==', shift)
			.where('date', '>=', start)
			.where('date', '<=', end);
		return jobDayShiftCrewRef
			.get()
			.then((docs) => {
				docs.forEach((doc) => {
					this.addJobLog(jobId, 'Removed Crew member ' + crewFirstName + ' ' + crewLastName + ' from shift ' + shift + ' for ' + moment(day).format('MM/DD/YYYY'));
					return this.afs.collection('plannedDays').doc(doc.id).delete();
				});
			})
			.catch(function (error) {
				console.log('Error getting document:', error);
			});
	}

	removeShiftFromJobDay(jobId: string, shift: string, day: any) {
		const start = new Date(day);
		start.setHours(0, 0, 0, 0);
		const end = new Date(day);
		end.setHours(23, 59, 59, 999);

		const jobDayShiftRef = this.afs.collection('plannedDays').ref.where('jobId', '==', jobId).where('shift', '==', shift).where('date', '>=', start).where('date', '<=', end);
		this.addJobLog(jobId, 'Removed Shift ' + shift + ' for ' + moment(day).format('MM/DD/YYYY'));
		return jobDayShiftRef
			.get()
			.then((docs) => {
				docs.forEach((doc) => {
					return this.afs.collection('plannedDays').doc(doc.id).delete();
				});
			})
			.catch(function (error) {
				console.log('Error getting document:', error);
			});
	}

	renameShiftFromJobDay(name: any, jobId: string, shift: string, day: any) {
		console.log(name, jobId, shift, day);
		const start = new Date(day);
		start.setHours(0, 0, 0, 0);
		const end = new Date(day);
		end.setHours(23, 59, 59, 999);
		console.log(start, end);
		const jobDayShiftRef = this.afs.collection('plannedDays').ref.where('jobId', '==', jobId).where('shift', '==', shift).where('date', '>=', start).where('date', '<=', end);
		return jobDayShiftRef
			.get()
			.then((docs) => {
				console.log(docs);

				docs.forEach((doc) => {
					return this.afs
						.collection('plannedDays')
						.doc(doc.id)
						.set(
							{
								shift: name.shiftTime,
								shiftStart: name.shiftStart,
								shiftEnd: name.shiftEnd,
								TimeDuration: name.timeDuration,
								shiftStartDate: name.shiftStartDate,
								shiftEndDate: name.shiftEndDate,
							},
							{ merge: true }
						)
						.then((result) => {
							return true;
						});
				});
			})
			.catch(function (error) {
				console.log('Error getting document:', error);
			});
	}

	reConfirmJob(jobId: string) {
		return this.afs
			.collection('jobs')
			.doc(jobId)
			.set({ requestConfirmation: false }, { merge: true })
			.then((result) => {
				return this.addJobLog(jobId, 'Job Details Set to Reconfirmed');
			});
	}

	async confirmJob(job) {
		const { id: jobId, clientName, jobNumber, company, city, state, location, locationName, outOfTown, status, startDate, endDate } = job;
		const crewJobData = {
			clientName,
			jobNumber,
			jobId,
			active: true,
			company,
			city,
			state,
			location,
			locationName,
			outOfTown,
			status,
			startDate,
			endDate,
		};
		try {
			const plannedDays = await this.afs.collection('plannedDays').ref.where('jobId', '==', jobId).get();
			console.log(plannedDays);

			if (plannedDays.empty) {
				return Promise.resolve();
			}
			plannedDays.forEach(async (plannedDay) => {
				const plannedDayData = plannedDay.data();
				const { crewId, type } = plannedDayData;
				try {
					if (crewId && crewId !== '') {
						await this.afs.doc(`crewMembers/${crewId}/jobs/${jobId}`).set(crewJobData, { merge: true });
						const crew = await this.afs.doc(`crewMembers/${crewId}`).ref.get();
						if (!crew.exists) {
							return Promise.resolve();
						}
						const crewData = crew.data();
						const { firstname, lastname, cell, color } = crewData;
						const crewType = type ? type : '';
						const crewColor = color ? color : '';
						const jobCrewData: JobCrewMember = {
							id: crewId,
							created: new Date(),
							active: true,
							confirmed: false,
							firstname,
							lastname,
							type: crewType,
							cell,
							color: crewColor,
						};
						await this.afs.collection('jobs').doc(`${jobId}`).collection('crewMembers').doc(crewId).set(jobCrewData, { merge: true });
						const crewJobRef = await this.afs.collection('jobs').doc(jobId).collection('crewMembers').ref.where('id', '==', crewId).get();
						if (crewJobRef.empty) {
							const crewRef = await this.afs.collection('crewMembers').doc(crewId).ref.get();
							if (!crewRef.exists) {
								return Promise.resolve();
							}
							await this.addCrewMemberToJob(jobId, crewRef.data());
						}
					}
				} catch (err) {
					return Promise.reject(err);
				}
			});
			await this.afs.collection('jobs').doc(jobId).set({ requestConfirmation: true }, { merge: true });
			return this.addJobLog(jobId, 'Job Details Confirmed');
		} catch (err) {
			return Promise.reject(err);
		}
	}

	completeJob(jobId: string) {
		return this.afs
			.collection('jobs')
			.doc(jobId)
			.set({ status: 'Completed', jobPlanner: false, ganttChart: false }, { merge: true })
			.then((result) => {
				return this.addJobLog(jobId, 'Job set as Completed').then(() => {
					this.setConfirmedUsersToJob(jobId);
				});
			});
	}

	setConfirmedUsersToJob(jobId: string) {
		// FIND ALL SHIFTS FOR JOB
		return this.afs
			.collection('plannedDays')
			.ref.where('jobId', '==', jobId)
			.get()
			.then((plannedDays) => {
				plannedDays.forEach((plannedDay) => {
					const plannedDayData = plannedDay.data();
					if (plannedDayData.crewId && plannedDayData.crewId !== '' && plannedDayData.confirmed) {
						// CHECK IF MEMBER ADDED TO JOB
						const checkCrewID = this.afs.collection('jobs').doc(jobId).collection('crewMembers').ref.where('id', '==', plannedDayData.crewId);
						return checkCrewID.get().then((emails): any => {
							if (emails.empty) {
								const crewRef = this.afs.collection('crewMembers').doc(plannedDayData.crewId).ref;
								return crewRef.get().then((crewMembersDoc) => {
									const crewMembersRef = this.afs.collection('jobs').doc(`${jobId}`).collection('crewMembers');
									const jobCrewData: JobCrewMember = {
										id: crewMembersDoc.data().id,
										created: new Date(),
										active: true,
										confirmed: true,
										firstname: crewMembersDoc.data().firstname,
										lastname: crewMembersDoc.data().lastname,
										cell: crewMembersDoc.data().cell,
										color: crewMembersDoc.data().color,
									};
									return crewMembersRef.doc(crewMembersDoc.data().id).set(jobCrewData, { merge: true });
								});
							}
						});
					}
				});
			});
	}

	setCrewTypeForJobDayShift(type: string, day: any, jobId: string, shift: string, crew: string) {
		const start = new Date(day);
		start.setHours(0, 0, 0, 0);
		const end = new Date(day);
		end.setHours(23, 59, 59, 999);

		let tmpType = type;
		if (type === 'none') {
			tmpType = '';
		}

		const jobDayShiftCrewRef = this.afs
			.collection('plannedDays')
			.ref.where('jobId', '==', jobId)
			.where('shift', '==', shift)
			.where('date', '>=', start)
			.where('date', '<=', end)
			.where('crewId', '==', crew);
		return jobDayShiftCrewRef.get().then((docs) => {
			docs.forEach((doc) => {
				return this.afs
					.collection('plannedDays')
					.doc(doc.id)
					.set({ type: tmpType }, { merge: true })
					.then((result) => {});
			});
		});
	}

	setShiftReconfirmedForJobDay(type: string, day: any, jobId: string, shift: string) {
		const start = new Date(day);
		start.setHours(0, 0, 0, 0);
		const end = new Date(day);
		end.setHours(23, 59, 59, 999);

		const confirmed = false;
		const denied = false;

		const jobDayShiftCrewRef = this.afs
			.collection('plannedDays')
			.ref.where('jobId', '==', jobId)
			.where('shift', '==', shift)
			.where('date', '>=', start)
			.where('date', '<=', end);
		return jobDayShiftCrewRef.get().then((docs) => {
			const crewMembersPlannedDaysPromises = [];
			docs.forEach((doc) => {
				crewMembersPlannedDaysPromises.push(
					this.afs.collection('plannedDays').doc(doc.id).set({ confirmed: confirmed, denied: denied, requestConfirmation: true }, { merge: true })
				);
			});
			Promise.all(crewMembersPlannedDaysPromises).then(() => {
				// TODO: Require a spinner here
			});
		});
	}

	setCrewReconfirmedForJobDayShift(type: string, day: any, jobId: string, shift: string, crew: string, job?: any) {
		const start = new Date(day);
		start.setHours(0, 0, 0, 0);
		const end = new Date(day);
		end.setHours(23, 59, 59, 999);

		const confirmed = false;
		const denied = false;
		const dayConfirmed = job && job.requestConfirmation == true ? true : false;
		const jobDayShiftCrewRef = this.afs
			.collection('plannedDays')
			.ref.where('jobId', '==', jobId)
			.where('shift', '==', shift)
			.where('date', '>=', start)
			.where('date', '<=', end)
			.where('crewId', '==', crew);
		return jobDayShiftCrewRef.get().then((docs) => {
			const crewMemberPlannedDaysPromises = [];
			if (dayConfirmed) {
				docs.forEach((doc) => {
					crewMemberPlannedDaysPromises.push(
						this.afs.collection('plannedDays').doc(doc.id).set({ confirmed: confirmed, denied: denied, requestConfirmation: true, dayConfirmed: true }, { merge: true })
					);
				});
			} else {
				docs.forEach((doc) => {
					crewMemberPlannedDaysPromises.push(
						this.afs.collection('plannedDays').doc(doc.id).set({ confirmed: confirmed, denied: denied, requestConfirmation: true }, { merge: true })
					);
				});
			}
			Promise.all(crewMemberPlannedDaysPromises).then(() => {
				// TODO: Require a spinner here
			});
		});
	}

	setCrewConfirmedForJobDayShift(type: string, day: any, jobId: string, shift: string, crew: string) {
		const start = new Date(day);
		start.setHours(0, 0, 0, 0);
		const end = new Date(day);
		end.setHours(23, 59, 59, 999);

		let confirmed;
		let denied;
		if (type === 'Not Known') {
			confirmed = false;
			denied = false;
		}

		if (type === 'Confirm') {
			confirmed = true;
			denied = false;
			this.afs.collection('jobs').doc(jobId).collection('crewMembers').doc(crew).set({ confirmed: true }, { merge: true });

			this.afs.collection('crewMembers').doc(crew).collection('jobs').doc(jobId).set({ confirmed: true }, { merge: true });
		}

		if (type === 'Denied') {
			confirmed = false;
			denied = true;
		}

		const jobDayShiftCrewRef = this.afs
			.collection('plannedDays')
			.ref.where('jobId', '==', jobId)
			.where('shift', '==', shift)
			.where('date', '>=', start)
			.where('date', '<=', end)
			.where('crewId', '==', crew);
		return jobDayShiftCrewRef.get().then((docs) => {
			docs.forEach((doc) => {
				return this.afs
					.collection('plannedDays')
					.doc(doc.id)
					.set({ confirmed: confirmed, denied: denied }, { merge: true })
					.then((result) => {});
			});
		});
	}

	sendSMSToJobCrew(jobId, message) {
		return this.afs.collection('pendingJobSMS').add({ jobId: jobId, message: message });
	}
	sendSMSToJobShiftCrew(data) {
		const crewMembersFunc = () => {
			delete data.value[0];
			const tmpCrewMembersArray = [];
			for (const crewId in data.value) {
				if (data.value.hasOwnProperty(crewId)) {
					tmpCrewMembersArray.push(crewId);
				}
			}
			return tmpCrewMembersArray;
		};
		const saveData = {
			shiftName: data.key,
			message: data.message,
			jobId: data.jobId,
			crewMembers: crewMembersFunc(),
		};
		return this.afs.collection('pendingJobShiftSMS').add(saveData);
	}

	fetchShiftsOftheday(crewId, day) {
		const start = new Date(day);
		start.setHours(0, 0, 0, 0);
		const end = new Date(day);
		end.setHours(23, 59, 59, 999);
		return this.afs
			.collection('plannedDays', (ref) => ref.where('crewId', '==', crewId).where('date', '>=', start).where('date', '<=', end))
			.snapshotChanges()
			.pipe(
				map((changes) => {
					return changes.map((a) => {
						const data = a.payload.doc.data() as JobCrewMember;
						data.id = a.payload.doc.id;
						return data;
					});
				})
			);
	}
	createJobArchive(job: Job) {
		return this.afs
			.collection('jobsArchive')
			.doc(job.id)
			.set(job)
			.then((data) => {
				this.afs
					.collection('jobs')
					.doc(job.id)
					.delete()
					.then((res) => {});
			});
	}
	createPlandayArchive(planDays: PlannedDay) {
		return this.afs
			.collection('plannedDaysArchive')
			.doc(planDays.id)
			.set(planDays)
			.then((data) => {
				this.afs
					.collection('plannedDays')
					.doc(planDays.id)
					.delete()
					.then((res) => {});
			});
	}
	getLogActivity(data: any) {
		return this.afs
			.collection('logs', (ref) => ref.where('jobId', '==', data.jobId).where('plandayId', '==', data.id).orderBy('currentTime', 'asc'))
			.snapshotChanges()
			.pipe(
				map((actions: any) =>
					actions.map((a) => {
						const data = a.payload.doc.data();
						return data;
					})
				)
			);
	}
	isShiftExistForTheDate(date, jobId) {
		return this.afs
			.collection('plannedDays', (ref) =>
				ref.where('jobId', '==', jobId).where('date', '==', date).where('crewId', '==', '').where('crewFirstName', '==', '').where('crewLastName', '==', '')
			)
			.snapshotChanges()
			.pipe(
				map((changes) => {
					return changes.map((a) => {
						const data = a.payload.doc.data() as any;
						return data.shift;
					});
				})
			);
	}

	private _convertInto24Hours(date: string) {
		const [time, modifier] = date.trim().split(' ');
		let [hours, minutes]: any = time.split(':');

		if (hours === '12') {
			hours = '00';
		}

		if (modifier === 'PM') {
			hours = parseInt(hours, 10) + 12;
		}
		console.log(`${hours}: ${minutes}`);
		return `${hours}:${minutes}`;
	}
	setDateTime(date, time) {
		const d = moment(date).format('L'); // d = "12/12/2017"
		return moment(d + ' ' + time).format();
	}
}
