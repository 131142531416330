export const Unions = [
	{
		name: 'Definitions',
		field: 'definitions',
	},
	{
		name: 'Cement Masons Finishers 502s',
		field: 'cement',
	},
	{
		name: 'Laborers Local 25',
		field: 'labour',
	},
	{
		name: 'Non Union (Jobsite)',
		field: 'nonjob',
	},
	{
		name: 'Travel',
		field: 'travel',
	},
	{
		name: 'Administrative (Salaried)',
		field: 'administrative',
	},
];
export const UnionList = {
	labour: 'Laborers Local 25',
	nonjob: 'Non Union (Jobsite)',
	cement: 'Cement Masons Finishers 502s',
};

export const crewLabel = [
	{
		name: 'Personal Details',
		count: 1,
		label: 1,
	},
	{
		name: 'Payroll',
		count: 2,
		label: 2,
	},
	{
		name: 'Paid Time Off',
		count: 3,
		label: 3,
	},
	{
		name: 'Training & Experience',
		count: 4,
		label: 4,
	},
	{
		name: 'Job Activity',
		count: 5,
		label: 5,
	},
	{
		name: 'Certifications',
		count: 6,
		label: 6,
	},
	{
		name: 'Company Issue',
		count: 7,
		label: 7,
	},
];
export const SalariedEmployee = [
	{
		name: 'Personal Details',
		count: 1,
		label: 1,
	},
	{
		name: 'Payroll',
		count: 2,
		label: 2,
	},
	{
		name: 'Paid Time Off',
		count: 3,
		label: 3,
	},
	{
		name: 'Company Issue',
		count: 7,
		label: 4,
	},
];

export const SpecialEmployee = [
	{
		name: 'Personal Details',
		count: 1,
		label: 1,
	},
	{
		name: 'Payroll',
		count: 2,
		label: 2,
	},
	{
		name: 'Paid Time Off',
		count: 3,
		label: 3,
	},
	{
		name: 'Certifications',
		count: 6,
		label: 4,
	},
	{
		name: 'Company Issue',
		count: 7,
		label: 5,
	},
];

export const PermissionsBasedList = [
	{
		name: 'Training & Experience',
		count: 4,
		label: 1,
	},
	{
		name: 'Job Activity',
		count: 5,
		label: 2,
	},
	{
		name: 'Certifications',
		count: 6,
		label: 4,
	},
	{
		name: 'Company Issue',
		count: 7,
		label: 4,
	},
];

export const PermissionsBasedListSalaried = [
	{
		name: 'Company Issue',
		count: 7,
		label: 1,
	},
];

export const documentCategories = [
	{
		label: 'Asbestos',
		value: '0',
		subCategories: [
			{ label: 'License', value: 'License' },
			{ label: 'Certificate', value: 'Certificate' },
			{ label: 'Refresher', value: 'Refresher' },
			{ label: 'Other', value: 'Other' },
		],
	},
	{
		label: 'Lead ',
		value: '1',
		subCategories: [
			{ label: 'License', value: 'License' },
			{ label: 'Certificate', value: 'Certificate' },
			{ label: 'Refresher', value: 'Refresher' },
			{ label: 'Other', value: 'Other' },
		],
	},
	{
		label: 'OSHA',
		value: '2',
		subCategories: [
			{ label: '40-hour HAZWOPER', value: '40-hour HAZWOPER' },
			{ label: '8-hour HAZWOPER Refresher', value: '8-hour HAZWOPER Refresher' },
			{ label: 'OSHA 30 hour', value: 'OSHA 30 hour' },
			{ label: 'OSHA 10 hour', value: 'OSHA 10 hour' },
		],
	},
	{
		label: 'Medical',
		value: '3',
		subCategories: [
			{ label: 'Physicals', value: 'Physicals' },
			{ label: 'FIT Test', value: 'FIT Test' },
		],
	},
	{
		label: 'Other',
		value: '4',
		subCategories: [{ label: 'Training & Other', value: 'Training & Other' }],
	},
];
export const statesList = [
	{
		name: 'IL',
	},
	{
		name: 'WI',
	},
	{
		name: 'IN',
	},
	{
		name: 'MI',
	},
	{
		name: 'Other',
	},
];
export const docCategories = [
	{
		label: 'Illinois',
		value: 'Illinois',
		subCategories: [
			{ label: 'IL Asbestos Certificate', value: 'IL Asbestos Certificate' },
			{ label: 'TL Lead Certificate', value: 'TL Lead Certificate' },
			{ label: 'IL Asbestos License', value: 'IL Asbestos License' },
			{ label: 'TL Lead License', value: 'TL Lead License' },
		],
	},
	{
		label: 'Indiana',
		value: 'Indiana',
		subCategories: [
			{ label: 'IN Asbestos Certificate', value: 'IN Asbestos Certificate' },
			{ label: 'IN Asbestos License', value: 'IN Asbestos License' },
			{ label: '40 hour HAZWOPER', value: '40 hour HAZWOPER' },
			{
				label: '8 hour HAZWOPER Refresher',
				value: '8 hour HAZWOPER Refresher',
			},
		],
	},
	{
		label: 'Wisconsin',
		value: 'Wisconsin',
		subCategories: [
			{ label: 'WI Asbestos License', value: 'WI Asbestos License' },
			{ label: 'WI Asbestos Certificate', value: 'WI Asbestos Certificate' },
			{ label: 'OSHA 30 hour', value: 'OSHA 30 hour' },
			{ label: 'OSHA 10 Hour', value: 'OSHA 10 Hour' },
			{ label: 'Training Certificate', value: 'Training Certificate' },
		],
	},
	{
		label: 'Physicals',
		value: 'Physicals',
		subCategories: [{ label: 'FIT Test', value: 'FIT Test' }],
	},
	{
		label: 'Equipment issue',
		value: 'Equipment issue',
		subCategories: [
			{ label: 'Hard hat', value: 'Hard hat' },
			{ label: 'Hard Hat Flashlight', value: 'Hard Hat Flashlight' },
			{ label: 'Safety Glasses/Goggles', value: 'Safety Glasses/Goggles' },
			{ label: 'Steel Toe Work boots', value: 'Steel Toe Work boots' },
			{ label: 'Knee Pads', value: 'Knee Pads' },
			{ label: 'Face Mask – Half', value: 'Face Mask – Half' },
			{ label: 'Face Mask – Full', value: 'Face Mask – Full' },
			{ label: 'Screwdriver Testers', value: 'Screwdriver Testers' },
		],
	},
	{
		label: 'Safety Clothing',
		value: 'Safety Clothing',
		subCategories: [
			{ label: 'Summer Hi Viz T shirt', value: 'Summer Hi Viz T shirt' },
			{ label: 'Winter Hi Viz T shirt', value: 'Winter Hi Viz T shirt' },
			{ label: 'Hi Viz Vest', value: 'Hi Viz Vest' },
			{ label: 'Winter Hoodie', value: 'Winter Hoodie' },
			{ label: 'Winter Bomber Jacket', value: 'Winter Bomber Jacket' },
		],
	},
];
export const tierList = [
	{ name: 'Supervisor/Foreman', color: '1' },
	{ name: 'Lead-Guy', color: '2' },
	{ name: 'Worker 1st Choice', color: '3' },
	{ name: 'Workers 2nd Choice', color: '4' },
	{ name: 'Workers 3rd Choice', color: '5' },
	{ name: 'Job Planning', color: '6' },
	{ name: 'Back-up Support ', color: '7' },
	// { name: 'Admin', color: 'admin' },
	{ name: 'None', color: 'none' },
];

export const EquipmentList = [
	{
		value: 0,
		label: 'Respirator Full Face',
	},
	{
		value: 1,
		label: 'Respirator Half Face',
	},

	{
		value: 2,
		label: 'Steel Toe Work Boots',
	},
	{
		value: 3,
		label: 'Safety Glasses / Googles',
	},
	{
		value: 4,
		label: 'Knee Pads',
	},

	{
		value: 6,
		label: 'Hard Hat',
	},
	{ label: 'Hard Hat Flashlight', value: 8 },
	{
		value: 7,
		label: 'Hola Lights',
	},
];

export const electroicsItems = [
	{
		value: 1,
		label: 'IPhone & Charger',
	},
	{
		value: 2,
		label: 'IPad',
	},
	{
		value: 3,
		label: 'Apple Watch',
	},

	{
		value: 4,
		label: 'Laptop & Charger',
	},
	{
		value: 5,
		label: 'Printer',
	},
	{
		value: 6,
		label: 'Scanner',
	},
	{
		value: 7,
		label: 'PC And Camera',
	},
	{
		value: 8,
		label: 'Computer',
	},
	{
		value: 9,
		label: 'Paper Shredder',
	},
];

export const otherItems = [
	{
		value: 1,
		label: 'Vehicle & Keys',
	},
	{
		value: 2,
		label: 'Office Keys',
	},
	{
		value: 3,
		label: 'Warehouse Keys',
	},
];
export const BrandedClothing = [
	{
		value: 0,
		label: 'Winter Jacket',
	},
	{
		value: 1,
		label: 'Hoodie',
	},

	{
		value: 2,
		label: 'Hi Viz Winter T shirt',
	},
	// {
	// 	value: 3,
	// 	label: 'Safety Googles',
	// },
	{
		value: 4,
		label: 'Hi Viz Summer T shirt',
	},
	{
		value: 5,
		label: 'Beanie',
	},
	{
		value: 6,
		label: 'Cap',
	},
	{
		value: 7,
		label: 'Gloves',
	},
	{
		value: 8,
		label: 'Welding Shirt',
	},
	{
		value: 9,
		label: 'Mechanic Shirt',
	},
	{
		value: 10,
		label: 'Mechanic T Shirt',
	},
	{
		value: 11,
		label: 'Lead Guy Gift',
	},
];
export const crewTierArray = {
	'1': [],
	'2': [],
	'3': [],
	'4': [],
	'5': [],
	'6': [],
	'7': [],
};
export const LevelTierObject = {
	'1': {
		name: 'Lead Guy',
		id: 1,
		color: '#ff6700',
		textColor: '#000',
	},
	'2': {
		name: 'W0',
		id: 2,
		color: '#eeff1b',
		textColor: '#000',
	},
	'3': {
		name: 'W1',
		id: 3,
		color: '#b0bec5',
		textColor: '#000',
	},
	'4': {
		name: 'W2',
		id: 4,
		color: '#b2ebf2',
		textColor: '#000',
	},
	'5': {
		name: 'W3',
		id: 5,
		color: '#f48fb1',
		textColor: '#000',
	},
	'6': {
		name: 'W4',
		id: 6,
		color: '#ce93d8',
		textColor: '#000',
	},
	'7': {
		name: 'WZ',
		id: 7,
		color: '#0cede6',
		textColor: '#000',
	},
};
export const LevelTier = [
	{
		name: 'Lead Guy',
		id: 1,
		color: '#ff6700',
		textColor: '#000',
	},
	{
		name: 'W0',
		id: 2,
		color: '#eeff1b',
		textColor: '#000',
	},
	{
		name: 'W1',
		id: 3,
		color: '#b0bec5',
		textColor: '#000',
	},
	{
		name: 'W2',
		id: 4,
		color: '#b2ebf2',
		textColor: '#000',
	},
	{
		name: 'W3',
		id: 5,
		color: '#f48fb1',
		textColor: '#000',
	},
	{
		name: 'W4',
		id: 6,
		color: '#ce93d8',
		textColor: '#000',
	},
	{
		name: 'WZ',
		id: 7,
		color: '#0cede6',
		textColor: '#000',
	},
];

export const JobTypes = [
	{ type_id: '1', name: 'Equipment' },
	{ type_id: '2', name: 'Equipment Trouble Shooting' },
	{ type_id: '3', name: 'Coating' },
	{ type_id: '4', name: 'Overlays' },
	{ type_id: '5', name: 'Floor Polising' },
	{ type_id: '7', name: 'Others' },
	// { type_id: '7', name: 'Shotblasting' },
];

export const JobTypesSubCategory = [
	{ id: '1', name: 'Mechanical ' },
	{ id: '2', name: 'Electrical ' },
	{ id: '3', name: 'Others' },
];

export const jobSubsection = {
	1: 'Mechanical',
	2: 'Electrical',
	3: 'Others',
};
export const TypesToName = {
	pto: 'PTO',
	unpaid: 'Un-Paid',
	other: 'Other',
	training: 'Training',
};
export const leaveToNames = {
	new_vacation: 'Vacation',
	new_sick: 'Sick',
	new_half_day: 'Half Day',
	new_training: 'Training',
	new_un_paid: 'Un Paid',
	new_other: 'Other',
	new_personal: 'Personal',
};
export const TimeOffPlannerTypes = [
	{
		name: 'PTO',
		id: 'pto',
	},
	{
		name: 'Un-Paid',
		id: 'unpaid',
	},
	{
		name: 'Training',
		id: 'training',
	},
	{
		name: 'Other',
		id: 'other',
	},
];
export const TrainingLeaveTypes = [
	{
		name: 'Training',
		id: 'new_training',
		color: '#8FCD28',
	},
];
export const LeaveTypesListing = [
	{
		name: 'Vacation PTO',
		id: 'new_vacation',
		color: '#8FCD28',
		abbr: 'V',
		sub_name: 'Vacation',
	},
	{
		name: 'Personal PTO',
		id: 'new_personal',
		color: '#341e6e',
		abbr: 'P',
		sub_name: 'Personal',
	},
	{
		name: 'Sick PTO',
		id: 'new_sick',
		color: '#f00905',
		abbr: 'S',
		sub_name: 'Sick',
	},

	{
		name: 'Half Day PTO',
		id: 'new_half_day',
		color: '#00A4C8',
		abbr: '½',
		sub_name: 'Half Day',
	},
	{
		name: 'Training',
		id: 'training',
		color: '#f0840a',
		abbr: 'T',
		sub_name: 'Training',
	},
	{
		name: 'Other PTO',
		id: 'new_other',
		color: '#e08cf5',
		abbr: 'O',
		sub_name: 'Other',
	},

	{
		name: 'Unpaid',
		id: 'unpaid',
		color: '#FDDA0D',
		abbr: 'U',
		sub_name: 'Unpaid',
	},
];

export const LeaveTypes = [
	{
		name: 'Vacation',
		id: 'new_vacation',
		color: '#8FCD28',
	},
	{
		name: 'Personal',
		id: 'new_personal',
		color: '#341e6e',
	},
	{
		name: 'Sick',
		id: 'new_sick',
		color: '#f00905',
	},
	{
		name: 'Half Day',
		id: 'new_half_day',
		color: '#00A4C8',
	},
	{
		name: 'Training',
		id: 'new_training',
		color: '#f0840a',
	},
	{
		name: 'Other',
		id: 'new_other',
		color: '#e08cf5',
	},
];
export const LeaveTypesAvailable = [
	{
		name: 'Vacation',
		id: 'new_vacation',
		color: '#8FCD28',
	},
	{
		name: 'Sick',
		id: 'new_sick',
		color: '##f00905',
	},
	{
		name: 'Half Day',
		id: 'new_half_day',
		color: '#00A4C8',
	},
	{
		name: 'Personal',
		id: 'new_personal',
		color: '#341e6e',
	},
	{
		name: 'Other',
		id: 'new_other',
		color: '#e08cf5',
	},
];

export const LeaveColors: any = {
	new_vacation: {
		primary: '#8FCD28',
		jobsite: '#ffa500',
		non_jobsite: '#008000',
		salaried: '#0000FF',
		office: '#0000FF',
	},
	new_sick: {
		primary: '#f00905',
		jobsite: '#ffa500',
		non_jobsite: '#008000',
		salaried: '#0000FF',
		office: '#0000FF',
	},
	new_half_day: {
		primary: '#00A4C8',
		jobsite: '#ffa500',
		non_jobsite: '#008000',
		salaried: '#0000FF',
		office: '#0000FF',
	},
	new_training: {
		primary: '#f0840a',
		jobsite: '#ffa500',
		non_jobsite: '#008000',
		salaried: '#0000FF',
		office: '#0000FF',
	},
	new_un_paid: {
		primary: '#FDDA0D',
		jobsite: '#ffa500',
		non_jobsite: '#008000',
		salaried: '#0000FF',
		office: '#0000FF',
	},
	new_other: {
		primary: '#e08cf5',
		jobsite: '#ffa500',
		non_jobsite: '#008000',
		salaried: '#0000FF',
		office: '#0000FF',
	},
	new_personal: {
		primary: '#341e6e',
		jobsite: '#ffa500',
		non_jobsite: '#008000',
		salaried: '#0000FF',
		office: '#0000FF',
	},
	unpaid: {
		primary: '#FDDA0D',
		jobsite: '#ffa500',
		non_jobsite: '#008000',
		salaried: '#0000FF',
		office: '#0000FF',
	},
};
