import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
	{
		id: 'jobsite',
		title: 'JOBSITE',
		type: 'group',
		children: [
			{
				id: 'jobs',
				title: 'Jobs Summary',
				type: 'item',
				icon: 'assignment',
				url: '/pages/jobs/list',
				permissions: ['jobs'],
			},
			{
				id: 'planner',
				title: 'Job Planner',
				type: 'item',
				icon: 'today',
				url: '/pages/planner/newjobplanner',
				permissions: ['job-planner'],
			},

			{
				id: 'gantt',
				title: 'Gantt Chart',
				type: 'item',
				icon: 'bar_chart',
				url: '/pages/planner/chart',
				permissions: ['gantt-chart'],
			},
			// {
			// 	id: 'completedjobs',
			// 	title: 'Completed Jobs',
			// 	type: 'item',
			// 	icon: 'assignment_turned_in',
			// 	url: '/pages/planner/completedjobs',
			// 	permissions: ['completed-jobs'],
			// },
			{
				id: 'clients',
				title: 'Customers',
				type: 'item',
				last: true,
				icon: 'how_to_reg',
				url: '/pages/clients/list',
				permissions: ['customers'],
			},
		],
	},
	{
		id: 'reports',
		title: 'REPORTS',
		type: 'group',
		children: [
			{
				id: 'dashboard',
				title: 'Dashboard',
				type: 'item',
				icon: 'dashboard',
				url: '/pages/reports',
				permissions: ['dashboard'],
				last: true,
			},
		],
	},
	{
		id: 'empoyees',
		title: 'EMPLOYEES',
		type: 'group',
		children: [
			{
				id: 'crew',
				title: 'Employee Control Center',
				type: 'item',
				icon: 'group',
				url: '/pages/crew/list',
				permissions: ['ecc', 'ecc-jl'],
			},
			{
				id: 'leave-policy',
				title: 'Time-Off Planner (New)',
				type: 'item',
				icon: 'assignment',
				url: '/pages/leave-policy',
				permissions: ['timeoff-planner'],
			},
			{
				id: 'timeoff',
				title: 'Time-Off Planner (Old)',
				type: 'item',
				icon: 'today',
				url: '/pages/planner/timeoff',
				permissions: ['timeoff-planner'],
			},
			{
				id: 'recognition-calendar',
				title: 'Recognition Calendar',
				type: 'item',
				icon: 'assignment',
				url: '/pages/recognition-calendar',
				permissions: ['recognition-calendar'],
				last: true,
			},
		],
	},
	{
		id: 'admin',
		title: 'ADMIN',
		type: 'group',
		children: [
			{
				id: 'payroll',
				title: 'Payroll',
				type: 'item',
				icon: 'attach_money',
				url: '/pages/payroll',
				permissions: ['payroll'],
			},
			{
				id: 'unionrates',
				title: 'Union Rates & Rules',
				type: 'item',
				icon: 'how_to_reg',
				url: '/pages/union-rates',
				permissions: ['union-rates', 'union-def'],
			},
			{
				id: 'permissions',
				title: 'Permissions',
				type: 'item',
				icon: 'assignment',
				url: '/pages/permissions',
				permissions: ['permissions'],
			},
			{
				id: 'public-users',
				title: 'Public Users',
				type: 'item',
				icon: 'how_to_reg',
				url: '/pages/public-users',
				permissions: ['public-users'],
				last: false,
			},
			{
				id: 'training-videos',
				title: 'Training Videos',
				type: 'item',
				icon: 'videocam',
				url: '/pages/training-videos',
				permissions: ['training-videos'],
				last: false,
			},
		],
	},
	// {
	// 	id: 'dashboard2',
	// 	title: 'Live Chart',
	// 	type: 'item',
	// 	icon: 'dashboard',
	// 	url: '/pages/live-chart',
	// 	permissions: ['dashboard'],
	// },

	{
		id: 'diagnostics',
		title: 'Diagnostics',
		type: 'item',
		icon: 'how_to_reg',
		url: '/pages/diagnostics',
		permissions: ['diagnostics'],
	},
];
